import { graphql, Link } from 'gatsby';
import * as React from 'react';
import Layout from '~/components/global/Layout';

import { SeoContainer } from '../global/SeoContainer';

export default function JobPage({
  data: { wpPage, wp },
}) {
console.log('====> ', wpPage)
  return (
    <Layout wp={wp}>
      <SeoContainer post={wpPage} />
      <div className="relative p-2 mx-auto md:px-5 md:py-4 ">
        <div className="rounded-md default-blue-gradient">
          <div className="noisy">
            <div className="container relative flex items-center pt-[6rem] pb-8 md:pt-[10rem] md:pb-[9.5rem]">
              <div className="space-y-[8px]">
                <h6 className="text-h6">Job title</h6>
                <h1 className="text-h1">{wpPage?.title}</h1>
                <p className="text-lg">{wpPage.template?.templateLegal?.tagline}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <FlexibleContent content={wpPage?.page?.flexibleContent} /> */}
      <section className=" mt-8 mb-12 md:my-12 lg:mt-[97px] lg:mb-[200px]">
        <div className="container ">
          <div className="flex flex-wrap justify-center md:flex-nowrap">

            {/* content */}
            <div className="w-full md:w-9/12">
              <div className="flex flex-col">
                <div className="!max-w-full mt-4 prose md:mt-12" dangerouslySetInnerHTML={{ __html: wpPage?.content }} />
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center mt-12">
            <a className="inline-flex items-center space-x-[13px] transition-colors duration-200 ease-in-out  bg-blue text-white hover:bg-black hover:text-white rounded-sm py-3 px-9 tracking-[-.005rem]  font-heading text-[14px] xl:text-[16px] leaidng-[1.62] font-medium" href={`mailto:jobs@ablestar.com?subject=${wpPage?.title}`}>Apply Now</a>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export const pageQuery = graphql`
  query Job($id: String!) {
    wpPage(id: { eq: $id }) {
      ...SEO
      ...GeneratedWpPage
      template {
        ... on WpTemplate_Job {
          templateName
          templateLegal {
            tagline
            lastUpdated
            fieldGroupName
          }
        }
      }
      content
    }
    wp {
      ...GeneratedWp
    }
  }
`;
